<template>
  <section class="main-wrapper">
    <div class="h-100 row">
      <div class="col-md-4 h-100 ad">
        <div class="mx-auto h-100 flex-column d-flex justify-content-between w-75">
          <div class="pt-3">
            <img src="@/assets/images/exxtra/exxtra-logo.png" width="70px" alt="exxtra-logo" />
          </div>

          <div class="">
            <ol class="position-relative border-left list-unstyled">
              <li class="mb-5 ml-3 cursor-pointer">
                <span class="position-absolute d-flex align-items-center text-primary justify-content-center rounded-full stepper-icon">
                  <svg aria-hidden="true" class="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                </span>
                <h4 class="m-0 p-0 secondary-heading">
                  Contact Info
                </h4>
                <p class="m-0 p-0 lead text-white">
                  Provide contact information
                </p>
              </li>
              <li class="mb-5 ml-3 cursor-pointer">
                <span class="position-absolute d-flex align-items-center justify-content-center rounded-full stepper-icon">
                  <svg aria-hidden="true" class="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                </span>
                <h4 class="m-0 p-0 secondary-heading">
                  Education Info
                </h4>
                <p class="m-0 p-0 text-white">
                  Provide information about your studies
                </p>
              </li>
              <li class="mb-5 ml-3 cursor-pointer">
                <span class="position-absolute d-flex align-items-center justify-content-center rounded-full stepper-icon">
                  <svg aria-hidden="true" class="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                </span>
                <h1 class="m-0 p-0 secondary-heading">
                  Success
                </h1>
                <p class="m-0 p-0 text-white">
                  You have successfully registered
                </p>
              </li>
            </ol>

          </div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="col-md-8 h-100">
        <div class="h-100 flex-column d-flex align-items-center justify-content-center ">
          <div>
            <div class="text-center">
              <h2 class="py-2">
                Thank you for choosing Exxtra
              </h2>
            </div>
            <div>
              <h3>You are already registered to this service </h3>
              <h4 class="text-center">
                Stay in touch, We will contact you soon
              </h4>
            </div>
            <div class="text-center mt-2 pt-1">
              <h6 class="text-secondary">
Details
</h6>
              <h6>{{ getValueFromSource(graduate_applicant, 'full_name') }}</h6>
              <h6>{{ getValueFromSource(graduate_applicant, 'email') }}</h6>
              <h6>{{ getValueFromSource(graduate_applicant, 'phone') }}</h6>
            </div>
            <div class="text-center my-2">
              <Check />
            </div>
            <div class="text-center">
              <a @click="$router.push({ name: 'gradloans'})">
                Go home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Check from "@/assets/svg/Check.svg";

export default {
  name: "GraduateRegistered",
  components: {
    Check
  },
  data() {
    return {
      graduate_applicant: {}
    }
  },
  mounted() {
    const graduate_applicant = JSON.parse(localStorage.getItem('graduate_applicant'))
    if (!graduate_applicant) this.$router.push({ name: 'gradloans-contact' })
    this.graduate_applicant = graduate_applicant;
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/base/pages/page-grad-applications.scss';

</style>
